import Slider from "@mui/material/Slider";
import { BarPlot } from "@mui/x-charts/BarChart";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { Tree } from "containers/ProductFilteredList/hooks";
import useDebounce from "hooks/useDebounce";
import { useMemo, useState, memo } from "react";

import { Flex, Typography } from "@holibob-packages/ui-core/components";
import { useFormattedPrice } from "@holibob-packages/ui-core/hooks";
import { useStyles } from "@holibob-packages/ui-core/style";

type Range = [number, number];

export type PriceTree = Pick<Tree, "id" | "type" | "label" | "fieldName" | "kind"> & {
    tree?: {
        maxPrice?: number;
        minPrice?: number;
        markList: { value: number }[];
        currency: string;
        histogram?: number[];
    };
    selectedRange: { priceStart?: number; priceEnd?: number };
    onPriceChange: (range: [number, number]) => void;
};

type PriceSliderContainerProps = PriceTree;

export const PriceSliderContainer = (props: PriceSliderContainerProps) => {
    const { tree } = props;

    if (!tree || (tree.histogram ?? []).length <= 1) {
        return null;
    }

    return <PriceSlider {...props} />;
};

type PriceSliderProps = PriceTree;

const PriceSlider = (props: PriceSliderProps) => {
    const { tree, selectedRange, onPriceChange } = props;
    const { priceStart, priceEnd } = selectedRange;
    const rangeStart = priceStart ?? tree?.minPrice ?? 0;
    const rangeEnd = priceEnd ?? tree?.maxPrice ?? 0;
    const [range, setRange] = useState<Range>([rangeStart, rangeEnd]);

    const onChangeHandler = (range: number | number[]) => {
        if (!Array.isArray(range)) {
            return;
        }
        const [rangeStart, rangeEnd] = range;
        setRange([rangeStart, rangeEnd]);
    };

    function onChangeCommitted(range: number | number[]) {
        if (!Array.isArray(range)) {
            return;
        }

        const [rangeStart, rangeEnd] = range;
        onPriceChange([rangeStart, rangeEnd]);
    }

    return (
        <Flex column pt={2}>
            <HistogramWithRangeLabel tree={tree} range={range} />
            <Slider
                aria-label="Small"
                valueLabelDisplay="auto"
                value={range}
                onChange={(_, value) => onChangeHandler(value)}
                onChangeCommitted={(_, value) => onChangeCommitted(value)}
                step={1}
                min={tree!.minPrice}
                max={tree!.maxPrice}
            />
        </Flex>
    );
};

type HistogramProps = {
    productCountList: number[];
    priceList: (string | number)[] | undefined;
    colorList: string[];
};
const Histogram = memo(function Histogram({ productCountList, priceList, colorList }: HistogramProps) {
    return (
        <ResponsiveChartContainer
            series={[
                {
                    type: "bar",
                    data: productCountList,
                },
            ]}
            margin={{
                left: 0,
                right: 0,
                top: -25,
                bottom: 0,
            }}
            height={150}
            xAxis={[
                {
                    data: priceList,
                    scaleType: "band",
                    colorMap: {
                        type: "ordinal",
                        colors: colorList,
                    },
                },
            ]}
        >
            <BarPlot borderRadius={1} />
        </ResponsiveChartContainer>
    );
});

type HistogramWithRangeLabelProps = Pick<PriceTree, "tree"> & {
    range: Range;
};
const HistogramWithRangeLabel = ({ range, tree }: HistogramWithRangeLabelProps) => {
    const { productCountList, colorList, priceList } = useHistogramProps({ tree, range });
    const [minPrice, maxPrice] = range;

    const minFormattedPrice = useFormattedPrice(minPrice, { currency: tree!.currency });
    const maxFormattedPrice = useFormattedPrice(maxPrice, { currency: tree!.currency });

    return (
        <Flex column gap={2}>
            <Typography>
                {minFormattedPrice} - {maxFormattedPrice}
            </Typography>
            <Histogram productCountList={productCountList} colorList={colorList} priceList={priceList} />
        </Flex>
    );
};

const useHistogramProps = ({ tree, range }: HistogramWithRangeLabelProps) => {
    const style = useStyles();
    const [startPrice, endPrice] = useDebounce(range);
    const theme = useMemo(
        () => ({ palette: style.theme.palette.primary, direction: style.theme.direction }),
        [style.theme]
    );
    return useMemo(() => {
        const productCountList = [...(tree?.histogram ?? [])];
        const markList = tree?.markList ?? [];
        const priceList = productCountList;
        const colorList = markList.map((mark) =>
            mark.value < startPrice || mark.value > endPrice ? theme.palette.light : theme.palette.dark
        );
        if (theme.direction === "rtl") {
            return {
                priceList: priceList.reverse(),
                colorList: colorList.reverse(),
                productCountList: productCountList.reverse(),
            };
        }
        return { productCountList, colorList, priceList };
    }, [startPrice, endPrice, theme, tree]);
};
